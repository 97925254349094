<template>
  <v-container />
</template>

<script>
  // import { api } from '@/api'

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        stats: {
        },
      }
    },

    computed: {
    },

    created () {
    },

    methods: {
      async loadData () {
        this.loading = true
        this.loading = false
      },
    },
  }
</script>
